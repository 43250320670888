import { render, staticRenderFns } from "./NumberService.vue?vue&type=template&id=3982239f&scoped=true&"
import script from "./NumberService.vue?vue&type=script&lang=js&"
export * from "./NumberService.vue?vue&type=script&lang=js&"
import style0 from "../assets/css/numberservice.css?vue&type=style&index=0&id=3982239f&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3982239f",
  null
  
)

export default component.exports